import { ChangeDetectionStrategy, Component, inject, OnInit, Signal, viewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { DOCUMENT } from '@angular/common';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { SvgRegistry } from '@owain/fast-svg/svg-registry.service';
import { JsonLdService } from '../../services/seo/json-ld.service';

@Component({
  selector: 'app-application-update-modal',
  templateUrl: './application-update.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalComponent, FastSvgComponent, TranslocoPipe],
})
export class ApplicationUpdateComponent implements OnInit {
  private readonly svgRegistry: SvgRegistry = inject(SvgRegistry);
  private readonly modal: Signal<ModalComponent<any>> = viewChild.required('modalComponent', { read: ModalComponent });
  private readonly document: Document = inject(DOCUMENT);
  private readonly jsonLdService: JsonLdService = inject(JsonLdService);

  public close(): void {
    if (this.modal()) {
      this.modal().close();
    }
  }

  public reloadApplication(): void {
    this.document.location.reload();
  }

  ngOnInit(): void {
    this.svgRegistry.fetchSvgs('icons/lightbulb');

    this.jsonLdService.addHeroiconImage('icons/lightbulb');

    this.jsonLdService.inject();
  }
}
