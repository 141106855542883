import { APP_ID, ApplicationConfig, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withPreloading } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';

import { DOCUMENT, provideCloudflareLoader } from '@angular/common';
import { provideServiceWorker } from '@angular/service-worker';
import { provideNotifier } from '@owain/notifier/lib/notifier.module';
import { provideErrorTailorConfig } from '@ngneat/error-tailor';
import { DefaultFormErrorComponent } from './components/form-error/default-form-error.component';
import {
  provideIndexedDBDataBaseName,
  provideIndexedDBStoreName,
  provideLocalStoragePrefix,
} from '@ngx-pwa/local-storage';
import { environment } from '../environments/environment';
import { provideQuicklink } from '@owain/quicklink/quicklink.module';
import { QuicklinkStrategy } from '@owain/quicklink/quicklink-strategy.service';
import { provideRecaptchaV2V3Config } from '@owain/recaptcha/recaptcha-v3.module';
import { WINDOW, windowProvider } from '@owain/tokens/window.provider';
import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
  withIncrementalHydration,
} from '@angular/platform-browser';
import { LOCALES_TOKEN } from '@owain/transloco-utils/localize-router.tokens';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: APP_ID, useValue: 'archery-scoring' },
    provideExperimentalZonelessChangeDetection(),
    provideClientHydration(
      withIncrementalHydration(),
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
      })
    ),
    provideIndexedDBDataBaseName('archeryScoringDatabase'),
    provideIndexedDBStoreName('archeryScoringStore'),
    provideLocalStoragePrefix('archeryscoring_'),
    provideQuicklink(),
    provideRouter(
      APP_ROUTES,
      withPreloading(QuicklinkStrategy),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withComponentInputBinding()
      // withViewTransitions(),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideRecaptchaV2V3Config({
      v2SiteKey: environment.recaptchaV2,
      v3SiteKey: environment.recaptchaV3,
    }),
    provideCloudflareLoader('https://archery-scoring.com/'),
    provideTransloco({
      config: {
        availableLangs: ['en', 'nl'],
        defaultLang: 'en',
        fallbackLang: ['en', 'nl'],
        missingHandler: {
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        flatten: {
          aot: !isDevMode(),
        },
      },
    }),
    provideTranslocoLocale({
      localeConfig: {
        global: {
          date: {
            dateStyle: 'long',
            timeStyle: 'short',
          },
        },
      },
      langToLocaleMapping: {
        en: 'en-US',
        nl: 'nl-NL',
      },
    }),
    { provide: LOCALES_TOKEN, useValue: ['en', 'nl'] },
    provideErrorTailorConfig({
      controlErrorComponent: DefaultFormErrorComponent,
      controlCustomClass: 'form-field-error',
      errors: {
        useFactory(service: TranslocoService) {
          return {
            required: () => service.translate('formerrors-required'),
            email: () => service.translate('formerrors-email'),
            passwordMismatch: () => service.translate('formerrors-passwordMismatch'),
            minlength: ({ requiredLength }) => service.translate('formerrors-minlength', { length: requiredLength }),
            maxlength: ({ requiredLength }) => service.translate('formerrors-maxlength', { length: requiredLength }),
          };
        },
        deps: [TranslocoService],
      },
    }),
    provideNotifier({
      theme: 'archery-scoring',
      behaviour: {
        autoHide: 2500,
      },
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10,
        },
      },
    }),
    {
      provide: WINDOW,
      useFactory: (document: Document) => windowProvider(document),
      deps: [DOCUMENT],
    },
  ],
};
