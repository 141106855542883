import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { mergeWith, Observable, of } from 'rxjs';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import type { IArrows } from '@archery-scoring/models/equipment.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { EquipmentArrowsStore } from '../../store/equipment/equipment-arrows.store';
import { CallState } from '@owain/store-features/features/call-state/call-state.feature';

export const profileArrowsResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<IArrows[] | IMiscHttpError | null>
  | Promise<IArrows[] | IMiscHttpError | null>
  | IArrows[]
  | IMiscHttpError
  | null => {
  const equipmentArrowsStore: InstanceType<typeof EquipmentArrowsStore> = inject(EquipmentArrowsStore);

  if (equipmentArrowsStore.arrowsLoaded()) {
    return equipmentArrowsStore.arrowEntities();
  }

  const router: Router = inject(Router);

  const error$: Observable<null> = toObservable(equipmentArrowsStore.arrowsError).pipe(
    filter((err: string | null): err is string => err != null),
    tap((err: string): void => console.error(err)),
    tap((): Promise<boolean> => router.navigateByUrl('/500')),
    map((): null => null)
  );

  const loaded$: Observable<IArrows[]> = toObservable(equipmentArrowsStore.arrowsCallState).pipe(
    filter((callState: CallState): callState is 'loaded' => callState === 'loaded'),
    switchMap((): Observable<IArrows[]> => {
      return of(equipmentArrowsStore.arrowEntities());
    })
  );
  equipmentArrowsStore.getArrows();

  return loaded$.pipe(mergeWith(error$));
};
