import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { mergeWith, Observable, of } from 'rxjs';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import type { IBows } from '@archery-scoring/models/equipment.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { EquipmentBowsStore } from '../../store/equipment/equipment-bows.store';
import { CallState } from '@owain/store-features/features/call-state/call-state.feature';

export const profileBowsResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<IBows[] | IMiscHttpError | null>
  | Promise<IBows[] | IMiscHttpError | null>
  | IBows[]
  | IMiscHttpError
  | null => {
  const equipmentBowsStore: InstanceType<typeof EquipmentBowsStore> = inject(EquipmentBowsStore);

  if (equipmentBowsStore.bowsLoaded()) {
    return equipmentBowsStore.bowEntities();
  }

  const router: Router = inject(Router);

  const error$: Observable<null> = toObservable(equipmentBowsStore.bowsError).pipe(
    filter((err: string | null): err is string => err != null),
    tap((err: string): void => console.error(err)),
    tap((): Promise<boolean> => router.navigateByUrl('/500')),
    map((): null => null)
  );

  const loaded$: Observable<IBows[]> = toObservable(equipmentBowsStore.bowsCallState).pipe(
    filter((callState: CallState): callState is 'loaded' => callState === 'loaded'),
    switchMap((): Observable<IBows[]> => of(equipmentBowsStore.bowEntities()))
  );
  equipmentBowsStore.getBows();

  return loaded$.pipe(mergeWith(error$));
};
