<app-modal #modalComponent>
  <div slot="popup">
    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 dark:bg-slate-800">
      @defer (hydrate never) {
        <fast-svg class="text-gray-900 dark:text-gray-100" name="icons/lightbulb" size="25" />
      }
    </div>
    <div class="mt-3 sm:mt-5 prose text-center">
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-title">
        {{ 'applicationupdatetitle' | transloco }}
      </h3>
    </div>
    <div class="prose">
      <div class="mt-2">
        <p class="text-sm text-gray-600 dark:text-gray-200">
          {{ 'applicationupdatesubtitle' | transloco }}
        </p>
      </div>
      <div class="mt-2">
        <p class="text-sm text-gray-600 dark:text-gray-200">
          {{ 'applicationupdatesubsubtitle' | transloco }}
        </p>
      </div>
    </div>
    <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
      <button
        (click)="close()"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:text-sm"
        type="button">
        {{ 'no' | transloco }}
      </button>
      <button
        (click)="reloadApplication()"
        class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none sm:mt-0 sm:text-sm"
        type="button">
        {{ 'yes' | transloco }}
      </button>
    </div>
  </div>
</app-modal>
