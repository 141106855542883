import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { environment } from '../../environments/environment';
import cacheBusting from './i18n-cache-busting.json';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TranslocoProductionHttpLoader implements TranslocoLoader {
  private readonly httpClient: HttpClient = inject(HttpClient);

  getTranslation(lang: string): Observable<Translation> {
    return this.httpClient.get<Translation>(
      // @ts-ignore
      `${environment.baseUrl}/assets/gen/i18n/${lang}.json?v=${cacheBusting[lang]}`
    );
  }
}
