import { ChangeDetectionStrategy, Component, inject, ViewContainerRef } from '@angular/core';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { LinkDirective } from '@owain/quicklink/link.directive';
import { CookiePreferencesService } from '../../services/cookie-preferences.service';
import { GtagService } from '../../services/gtag.service';
import { LocalizeRouterPipe } from '@owain/transloco-utils/localize-router.pipe';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FastSvgComponent, LocalizeRouterPipe, TranslocoPipe, RouterLink, LinkDirective],
})
export class AppFooterComponent {
  public readonly year: number = new Date().getFullYear();
  public readonly inEu: boolean = Intl.DateTimeFormat().resolvedOptions().timeZone.startsWith('Europe');
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  private readonly cookiePreferencesService: CookiePreferencesService = inject(CookiePreferencesService);
  private readonly gtagService: GtagService = inject(GtagService);

  public openCookiePreferences(): void {
    this.cookiePreferencesService.open(this.viewContainerRef);

    this.gtagService.event('click', 'UI', 'cookie_preferences_modal');
  }
}
