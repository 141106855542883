import {
  CanMatchFn,
  GuardResult,
  MaybeAsync,
  RedirectCommand,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { inject, PLATFORM_ID } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from '../../services/http/auth.service';
import { UserStore } from '../../store/user/user.store';
import type { IUser } from '@archery-scoring/models/user.model';
import { isPlatformBrowser } from '@angular/common';
import { ApplicationUiStore } from '../../store/application-ui/application-ui.store';
import { Observable, of } from 'rxjs';

export const superAdminMatchGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> => {
  const userStore: InstanceType<typeof UserStore> = inject(UserStore);

  if (userStore.isSuperAdmin()) {
    return true;
  }

  const applicationUiStore: InstanceType<typeof ApplicationUiStore> = inject(ApplicationUiStore);
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  const platformId: Object = inject(PLATFORM_ID);

  return authService.status(isPlatformBrowser(platformId)).pipe(
    take(1),
    map((user: IUser | null): true | RedirectCommand => {
      if (user == null) {
        applicationUiStore.setRedirectUrl(route.path);

        const targetOfCurrentNavigation: UrlTree | undefined = router.getCurrentNavigation()?.finalUrl;
        const redirect: UrlTree = router.parseUrl('/401');

        return new RedirectCommand(redirect, {
          browserUrl: targetOfCurrentNavigation,
        });
      } else if (user.role !== 'SUPERADMIN') {
        applicationUiStore.setRedirectUrl(route.path);

        const targetOfCurrentNavigation: UrlTree | undefined = router.getCurrentNavigation()?.finalUrl;
        const redirect: UrlTree = router.parseUrl('/403');

        return new RedirectCommand(redirect, {
          browserUrl: targetOfCurrentNavigation,
        });
      }

      return true;
    }),
    catchError((err: any): Observable<boolean> => {
      console.error('[superAdminMatchGuard] error:', err);
      return of(false);
    })
  );
};
