import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly httpClient: HttpClient = inject(HttpClient);

  getTranslation(lang: string): Observable<Translation> {
    return this.httpClient.get<Translation>(`${environment.baseUrl}/assets/i18n/${lang}.json`);
  }
}
