import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { mergeWith, Observable, of } from 'rxjs';
import type { IOrganizationWithUsers } from '@archery-scoring/models/organization.model';
import { inject } from '@angular/core';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminOrganizationStore } from '../../store/admin/admin-organizations.store';
import { CallState } from '@owain/store-features/features/call-state/call-state.feature';

export const adminOrganizationResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<IOrganizationWithUsers[] | IMiscHttpError | null>
  | Promise<IOrganizationWithUsers[] | IMiscHttpError | null>
  | IOrganizationWithUsers[]
  | IMiscHttpError
  | null => {
  const organizationUrl: string | null = route.paramMap.get('organization');

  if (!organizationUrl) {
    return null;
  }

  const sdminOrganizationStore: InstanceType<typeof AdminOrganizationStore> = inject(AdminOrganizationStore);

  if (sdminOrganizationStore.organizationsLoaded()) {
    const organizations: IOrganizationWithUsers[] = sdminOrganizationStore.organizationEntities();
    const org: IOrganizationWithUsers | undefined = organizations.find((org: IOrganizationWithUsers) => org.url === organizationUrl);
    if (org) {
      return organizations;
    }
  }

  const router: Router = inject(Router);

  const error$: Observable<null> = toObservable(sdminOrganizationStore.organizationsError).pipe(
    filter((err: string | null): err is string => err != null),
    tap((err: string): void => console.error(err)),
    tap((): Promise<boolean> => router.navigateByUrl('/500')),
    map((): null => null)
  );

  const loaded$: Observable<IOrganizationWithUsers[]> = toObservable(sdminOrganizationStore.organizationsCallState).pipe(
    filter((callState: CallState): callState is 'loaded' => callState === 'loaded'),
    switchMap((): Observable<IOrganizationWithUsers[]> => of(sdminOrganizationStore.organizationEntities()))
  );
  sdminOrganizationStore.getOrganizations();

  return loaded$.pipe(mergeWith(error$));
};
