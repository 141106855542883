import { APP_INITIALIZER, makeEnvironmentProviders } from '@angular/core';

import { TRANSLOCO_PRELOAD_LANGUAGES, TranslocoPreloadLangsService } from './preload-langs.service';
import { WINDOW, windowProvider } from '@owain/tokens/window.provider';
import { DOCUMENT } from '@angular/common';

export function provideTranslocoPreloadLangs(langs: string[]) {
  return makeEnvironmentProviders([
    { provide: TRANSLOCO_PRELOAD_LANGUAGES, useValue: langs },
    {
      provide: APP_INITIALIZER,
      useFactory: (translocoPreloadLangsService: TranslocoPreloadLangsService) => () => {
        translocoPreloadLangsService.register();
      },
      multi: true,
      deps: [TranslocoPreloadLangsService],
    },
    {
      provide: WINDOW,
      useFactory: (document: Document) => windowProvider(document),
      deps: [DOCUMENT],
    },
  ]);
}
