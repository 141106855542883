<footer aria-labelledby="footer-heading">
  <h2 class="sr-only" id="footer-heading">Footer</h2>
  <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
    <div class="xl:grid xl:grid-cols-3 xl:gap-8 mobile-inset-left mobile-inset-right">
      <div class="space-y-8">
        @defer (hydrate never) {
          <fast-svg name="logo/crossed-arrows" size="32" />
        }
        <p class="text-sm leading-6 text-gray-900 dark:text-gray-100">
          {{ 'footersubtitle' | transloco }}
        </p>
      </div>
      <div class="mt-6 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <ul class="mt-6 space-y-4" role="list">
              <li>
                <a
                  [routerLink]="['/'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'home' | transloco }}
                </a>
              </li>
              <li>
                <a

                  [routerLink]="['/about'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'about' | transloco }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/contact'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'contact' | transloco }}
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-10 md:mt-0">
            <ul class="mt-6 space-y-4" role="list">
              <li>
                <a
                  [routerLink]="['/scoring'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'scoring' | transloco }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/classes', 'international-field-archery-association'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'bows' | transloco }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <ul class="mt-6 space-y-4" role="list">
              <li>
                <a
                  [routerLink]="['/legal', 'cookie-policy'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'cookiepolicy' | transloco }}
                </a>
              </li>
              <li [hidden]="!inEu">
                <span
                  (click)="openCookiePreferences()"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 cursor-pointer">
                  {{ 'cookiepreferences' | transloco }}
                </span>
              </li>
              <li>
                <a
                  [routerLink]="['/sitemap'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'sitemap' | transloco }}
                </a>
              </li>
              <li [hidden]="inEu">
                <span
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 cursor-pointer">
                  &nbsp;
                </span>
              </li>
            </ul>
          </div>
          <div class="mt-10 md:mt-0">
            <ul class="mt-6 space-y-4" role="list">
              <li>
                <a
                  [routerLink]="['/legal', 'privacy-policy'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'privacypolicy' | transloco }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/legal', 'terms-of-service'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'termsofservice' | transloco }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="['/legal', 'eula'] | localize"
                  class="text-sm leading-6 text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                  {{ 'eula' | transloco }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-6 border-t border-gray-300 dark:border-gray-500 pt-8 md:flex md:items-center md:justify-between md:content-center duration-1000">
      <p
        class="text-xs leading-5 ext-gray-900 dark:text-gray-100 md:order-2 md:mt-0 text-center mobile-inset-left mobile-inset-right">
        {{ 'love' | transloco }}
      </p>
      <p
        class="md:mt8 text-xs leading-5 ext-gray-900 dark:text-gray-100 md:order-1 md:mt-0 text-center mobile-inset-left mobile-inset-right">
        &copy; {{ year }}
        <a class="underline duration-0" href="http://archery-events.nl/" target="_blank" rel="noopener noreferrer">Archery Events</a>,
        {{ 'rights' | transloco }}
      </p>
    </div>
  </div>
</footer>
