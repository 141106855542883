import { ApplicationConfig, isDevMode, mergeApplicationConfig } from '@angular/core';
import { appConfig } from './app.config';
import { BASE_URL, CAPTCHA_V3_SERVICE } from './tokens/injection.tokens';
import { TranslocoProductionHttpLoader } from './translate/transloco-production-loader';
import { TranslocoHttpLoader } from './translate/transloco-loader';
import { TRANSLOCO_LOADER } from '@jsverse/transloco';
import { provideHttpClient, withFetch, withInterceptors, withNoXsrfProtection } from '@angular/common/http';
import { jwtRefreshTokenInterceptor } from './interceptors/jwt-refresh-token.interceptor';
import { provideTranslocoPreloadLangs } from '@owain/transloco-preload/preload-langs.providers';
import { ReCaptchaV3Service } from '@owain/recaptcha/recaptcha-v3.service';
import { provideFastSVG, provideFastSVGPreloadImages } from '@owain/fast-svg/fast-svg.provider';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { browserTranslationsInterceptor } from './interceptors/browser-translations.interceptor';

const config: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withFetch(),
      withNoXsrfProtection(),
      withInterceptors([
        // csrfInterceptor,
        jwtRefreshTokenInterceptor,
        browserTranslationsInterceptor,
      ])
    ),
    provideAnimationsAsync(),
    provideFastSVG({
      url: (name: string) => `assets/svg/${name}.svg`,
    }),
    provideTranslocoPreloadLangs([
      // Languages
      'en',
      'nl',

      // Scopes
      'classes/en',
      'classes/nl',
      'ifaa/en',
      'ifaa/nl',
      'nfaa/en',
      'nfaa/nl',
      'usat/en',
      'usat/nl',
      'wa/en',
      'wa/nl',

      'errorpages/en',
      'errorpages/nl',

      'redirect/en',
      'redirect/nl',

      'contact/en',
      'contact/nl',

      'home/en',
      'home/nl',

      'profile/en',
      'profile/nl',

      'training/en',
      'training/nl',

      'scoring/en',
      'scoring/nl',

      'signin/en',
      'signin/nl',

      'organization/en',
      'organization/nl',

      'competition/en',
      'competition/nl',

      'legal/en',
      'legal/nl',
    ]),
    provideFastSVGPreloadImages([
      'logo/crossed-arrows',

      'icons/arrow',
      'icons/arrowup',
      'icons/bow',
      'icons/check',
      'icons/checkcircle',
      'icons/chevron-down',
      'icons/chevron-up',
      'icons/code',
      'icons/cog',
      'icons/email',
      'icons/exclamationcircle',
      'icons/general',
      'icons/identification',
      'icons/infocircle',
      'icons/lightbulb',
      'icons/password',
      'icons/security',
      'icons/x',
      'icons/xcircle',
      'icons/edit',
      'icons/trash',
      'icons/chevron-left',
      'icons/chevron-right',
      'icons/chevron-double-left',
      'icons/chevron-double-right',
      'icons/register',
      'icons/settings',
      'icons/sign-in',
      'icons/sign-out',
      'icons/star',
      'icons/ellipsis-vertical',
      'icons/eye',
      'icons/eye-slash',
      'icons/organizations',
      'icons/globe',
      'icons/map-pin',
      'icons/chart-bar',
      'icons/home',
      'icons/trophy',
      'icons/archive',

      'icons/menu-close',
      'icons/menu-hamburger',
      'icons/spinner',

      'flags/us',
      'flags/nl',

      'theme/moon',
      'theme/sun',

      'scoring/imperial',
      'scoring/metric',
      'scoring/metric_three_spot',
      'scoring/metric_three_spot_yellow_black',
      'scoring/worcester_white',

      'scoring/imperial_score',
      'scoring/imperial_on_metric_score',
      'scoring/metric_score',
      'scoring/metric_score_compound_indoor_three_spot',
      'scoring/metric_score_compound_indoor_three_spot_alt',
      'scoring/worcester_white_scores',

      'brands/facebook',
      'brands/x',
      'brands/whatsapp',
      'brands/messenger',
      'brands/telegram',
      'brands/gmail',
      'brands/apple-share',
      'brands/android-share',
      'brands/clipboard',
    ]),
    {
      provide: CAPTCHA_V3_SERVICE,
      useClass: ReCaptchaV3Service,
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: !isDevMode() ? TranslocoProductionHttpLoader : TranslocoHttpLoader,
    },
    {
      provide: BASE_URL,
      useValue: `${window.location.protocol}//${window.location.host}`,
    },
  ],
};

export const browserConfig = mergeApplicationConfig(appConfig, config);
