import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { mergeWith, Observable } from 'rxjs';
import type { IOrganizationWithCompetitions, IOrganizationWithUsers } from '@archery-scoring/models/organization.model';
import { inject } from '@angular/core';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import { OrganizationStore } from '../../store/organization/organization.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, tap } from 'rxjs/operators';

export const publicOrganizationResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<IOrganizationWithUsers | IMiscHttpError | null>
  | Promise<IOrganizationWithUsers | IMiscHttpError | null>
  | IOrganizationWithUsers
  | IMiscHttpError
  | null => {
  const organizationUrl: string | null = route.paramMap.get('organization');

  if (!organizationUrl) {
    return null;
  }

  const organizationStore: InstanceType<typeof OrganizationStore> = inject(OrganizationStore);
  const organization: IOrganizationWithCompetitions | null = organizationStore.organization();

  if (organization && organization.url === organizationUrl) {
    return organization;
  }

  const router: Router = inject(Router);

  const error$: Observable<null> = toObservable(organizationStore.error).pipe(
    filter((err: string | null): err is string => err != null),
    tap((err: string): void => console.error(err)),
    tap((): Promise<boolean> => router.navigateByUrl('/404')),
    map((): null => null)
  );

  const organization$: Observable<IOrganizationWithCompetitions> = toObservable(organizationStore.organization).pipe(filter(org => org != null));
  organizationStore.getOrganizationByUrl(organizationUrl);

  return organization$.pipe(mergeWith(error$));
};
