import { Routes } from '@angular/router';
import { languageMatchGuard } from './guards/language.guard';
import { profileMatchGuard } from './guards/profile/profile.guard';
import { superAdminMatchGuard } from './guards/superadmin/super-admin.guard';
import { superadminOrganizationsResolver } from './resolvers/superadmin/organizations.resolver';
import { superadminUsersResolver } from './resolvers/superadmin/users.resolver';
import { profileBowsResolver } from './resolvers/profile/bows.resolver';
import { profileArrowsResolver } from './resolvers/profile/arrows.resolver';
import { adminMatchGuard } from './guards/admin/admin.guard';
import { adminOrganizationResolver } from './resolvers/admin/organization.resolver';
import { publicOrganizationResolver } from './resolvers/organizations/organization.resolver';
import { adminCompetitionsResolver } from './resolvers/admin/competition.resolver';
import { userGuard } from './guards/user/user.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canMatch: [languageMatchGuard],
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent),
  },

  {
    path: 'external-totp',
    pathMatch: 'full',
    loadComponent: () => import('./pages/external-totp/external-totp.component').then(m => m.ExternalTotpComponent),
    canMatch: [userGuard],
    data: {
      en: {
        title: 'Sign in',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Sign in',
            item: 'external-totp',
          },
        ],
      },
      nl: {
        title: 'Inloggen',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Inloggen',
            item: 'external-totp',
          },
        ],
      },
    },
  },

  // Error pages
  {
    path: '400',
    pathMatch: 'full',
    loadComponent: () => import('./pages/error/bad-request/bad-request.component').then(m => m.BadRequestComponent),
    data: {
      en: {
        title: 'Bad Request',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Bad Request',
            item: '400',
          },
        ],
      },
      nl: {
        title: 'Foutieve aanvraag',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Foutieve aanvraag',
            item: '400',
          },
        ],
      },
    },
  },
  {
    path: '401',
    pathMatch: 'full',
    loadComponent: () => import('./pages/error/unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent),
    data: {
      en: {
        title: 'Unauthorized',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Unauthorized',
            item: '401',
          },
        ],
      },
      nl: {
        title: 'Ongeautoriseerd',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Ongeautoriseerd',
            item: '401',
          },
        ],
      },
    },
  },
  {
    path: '403',
    pathMatch: 'full',
    loadComponent: () => import('./pages/error/forbidden/forbidden.component').then(m => m.ForbiddenComponent),
    data: {
      en: {
        title: 'Forbidden',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Forbidden',
            item: '403',
          },
        ],
      },
      nl: {
        title: 'Verboden toegang',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Verboden toegang',
            item: '403',
          },
        ],
      },
    },
  },
  {
    path: '404',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/error/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    // canMatch: [notFoundGuard],
    data: {
      en: {
        title: 'Page not found',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Page not found',
            item: '404',
          },
        ],
      },
      nl: {
        title: 'Pagina kon niet worden gevonden',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Pagina kon niet worden gevonden',
            item: '404',
          },
        ],
      },
    },
  },
  {
    path: '500',
    pathMatch: 'full',
    loadComponent: () => import('./pages/error/server-error/server-error.component').then(m => m.ServerErrorComponent),
    data: {
      en: {
        title: 'Server error',
        breadcrumbs: [
          {
            name: 'Home',
            item: '/',
          },
          {
            name: 'Server error',
            item: '500',
          },
        ],
      },
      nl: {
        title: 'Server error',
        breadcrumbs: [
          {
            name: 'Startpagina',
            item: '/',
          },
          {
            name: 'Server error',
            item: '500',
          },
        ],
      },
    },
  },

  // Cloudflare routes
  {
    path: 'challenge',
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/app-challenge/app-challenge.component').then(m => m.AppChallengeComponent),
    data: {
      hideLayout: true,
    },
  },
  {
    path: 'captcha',
    pathMatch: 'full',
    loadComponent: () => import('./components/app-captcha/app-captcha.component').then(m => m.AppCaptchaComponent),
    data: {
      hideLayout: true,
    },
  },

  {
    path: 'shell',
    pathMatch: 'full',
    loadComponent: () => import('./components/app-shell/app-shell.component').then(m => m.AppShellComponent),
  },

  {
    path: ":lang",
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent),
        data: {
          en: {
            title: 'Home',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
            ],
          },
          nl: {
            title: 'Startpagina',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
            ],
          },
        },
      },
      {
        path: 'scoring',
        loadComponent: () => import('./pages/scoring/scoring.component').then(m => m.ScoringComponent),
        data: {
          en: {
            title: 'Scoring system',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Scoring',
                item: 'scoring',
              },
            ],
          },
          nl: {
            title: 'Score systeem',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Score',
                item: 'scoring',
              },
            ],
          },
        },
      },
      {
        path: 'classes',
        pathMatch: 'full',
        loadComponent: () => import('./pages/classes/classes.component').then(m => m.ClassesComponent),
        data: {
          en: {
            title: 'Bow classes',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Bow classes',
                item: 'classes',
              },
            ],
          },
          nl: {
            title: 'Boogklassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Boogklassen',
                item: 'classes',
              },
            ],
          },
        },
      },
      {
        path: 'classes/:association',
        pathMatch: 'full',
        loadComponent: () => import('./pages/classes/classes.component').then(m => m.ClassesComponent),
        data: {
          titleParam: 'association',
          en: {
            title: 'Bow classes',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Bow classes',
                item: 'classes',
              },
              {
                name: ':association',
                item: 'classes/:association',
              },
            ],
          },
          nl: {
            title: 'Boogklassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Boogklassen',
                item: 'classes',
              },
              {
                name: ':association',
                item: 'classes/:association',
              },
            ],
          },
        },
      },
      {
        path: 'classes/:association/:sub',
        pathMatch: 'full',
        loadComponent: () => import('./pages/classes/classes.component').then(m => m.ClassesComponent),
        data: {
          titleParam: 'association',
          en: {
            title: 'Bow classes',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Bow classes',
                item: 'classes',
              },
              {
                name: ':association',
                item: 'classes/:association',
              },
              {
                name: ':sub',
                item: 'classes/:association/:sub',
              },
            ],
          },
          nl: {
            title: 'Boogklassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Boogklassen',
                item: 'classes',
              },
              {
                name: ':association',
                item: 'classes/:association',
              },
              {
                name: ':sub',
                item: 'classes/:association/:sub',
              },
            ],
          },
        },
      },
      {
        path: 'contact',
        loadComponent: () => import('./pages/contact/contact.component').then(m => m.ContactComponent),
        data: {
          en: {
            title: 'Contact',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Contact',
                item: 'contact',
              },
            ],
          },
          nl: {
            title: 'Contact',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Contact',
                item: 'contact',
              },
            ],
          },
        },
      },

      {
        path: 'redirect',
        loadComponent: () => import('./pages/redirect/redirect.component').then(m => m.RedirectComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Redirect',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Redirect',
                item: 'redirect',
              },
            ],
          },
          nl: {
            title: 'Terugleiden',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Terugleiden',
                item: 'redirect',
              },
            ],
          },
        },
      },

      {
        path: 'signin',
        loadComponent: () => import('./pages/sign-in/sign-in.component').then(m => m.SignInComponent),
        canMatch: [userGuard],
        data: {
          en: {
            title: 'Sign in',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Sign in',
                item: 'signin',
              },
            ],
          },
          nl: {
            title: 'Inloggen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Inloggen',
                item: 'signin',
              },
            ],
          },
        },
      },
      {
        path: 'signout',
        loadComponent: () => import('./pages/sign-out/sign-out.component').then(m => m.SignOutComponent),
        data: {
          en: {
            title: 'Sign out',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Sign out',
                item: 'signout',
              },
            ],
          },
          nl: {
            title: 'Uitloggen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Uitloggen',
                item: 'signout',
              },
            ],
          },
        },
      },
      {
        path: 'register',
        loadComponent: () => import('./pages/register/register.component').then(m => m.RegisterComponent),
        canMatch: [userGuard],
        data: {
          en: {
            title: 'Register',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Register',
                item: 'register',
              },
            ],
          },
          nl: {
            title: 'Registreren',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Registreren',
                item: 'register',
              },
            ],
          },
        },
      },
      {
        path: 'reset-password',
        pathMatch: 'full',
        loadComponent: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
        canMatch: [userGuard],
        data: {
          en: {
            title: 'Reset password',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Reset password',
                item: 'reset-password',
              },
            ],
          },
          nl: {
            title: 'Wachtwoord resetten',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Wachtwoord resetten',
                item: 'reset-password',
              },
            ],
          },
        },
      },
      {
        path: 'reset-password/:token',
        pathMatch: 'full',
        loadComponent: () => import('./pages/reset-password/new-password.component').then(m => m.NewPasswordComponent),
        canMatch: [userGuard],
        data: {
          en: {
            title: 'Reset password',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Reset password',
                item: 'reset-password',
              },
            ],
          },
          nl: {
            title: 'Wachtwoord resetten',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Wachtwoord resetten',
                item: 'reset-password',
              },
            ],
          },
        },
      },
      {
        path: 'verify-email/:token',
        loadComponent: () => import('./pages/verify-email/verify-email.component').then(m => m.VerifyEmailComponent),
        data: {
          en: {
            title: 'Email verification',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Email verification',
                item: 'verify-email',
              },
            ],
          },
          nl: {
            title: 'E-mailadres verifieren',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'E-mailadres verifieren',
                item: 'verify-email',
              },
            ],
          },
        },
      },

      {
        path: 'profile/settings',
        loadComponent: () => import('./pages/profile/settings/settings.component').then(m => m.ProfileSettingsComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Profile - General',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'General',
                item: 'profile/settings',
              },
            ],
          },
          nl: {
            title: 'Profiel - Algemeen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Algemeen',
                item: 'profile/settings',
              },
            ],
          },
        },
      },
      {
        path: 'profile/security',
        loadComponent: () => import('./pages/profile/security/security.component').then(m => m.ProfileSecurityComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Profile - Security',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Security',
                item: 'profile/security',
              },
            ],
          },
          nl: {
            title: 'Profiel - Beveiliging',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Beveiliging',
                item: 'profile/security',
              },
            ],
          },
        },
      },

      {
        path: 'profile/bows',
        loadComponent: () => import('./pages/profile/bows/bows.component').then(m => m.ProfileBowsComponent),
        canMatch: [profileMatchGuard],
        resolve: { bows: profileBowsResolver },
        data: {
          en: {
            title: 'Profile - Bow profiles',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Bow profiles',
                item: 'profile/bows',
              },
            ],
          },
          nl: {
            title: 'Profiel - Boog profielen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Boog profielen',
                item: 'profile/bows',
              },
            ],
          },
        },
      },
      {
        path: 'profile/bows/new',
        loadComponent: () => import('./pages/profile/bows/new-bow.component').then(m => m.ProfileNewBowComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Profile - Add bow profile',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Bow profiles',
                item: 'profile/bows',
              },
              {
                name: 'Add bow profile',
                item: 'profile/bows/new',
              },
            ],
          },
          nl: {
            title: 'Profiel - Nieuw boog profiel',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Boog profielen',
                item: 'profile/bows',
              },
              {
                name: 'Nieuw boog profiel',
                item: 'profile/bows/new',
              },
            ],
          },
        },
      },
      {
        path: 'profile/bows/edit/:id',
        pathMatch: 'full',
        loadComponent: () => import('./pages/profile/bows/edit-bow.component').then(m => m.ProfileEditBowComponent),
        canMatch: [profileMatchGuard],
        resolve: { bows: profileBowsResolver },
        data: {
          en: {
            title: 'Profile - Edit bow profile',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Bow profiles',
                item: 'profile/bows',
              },
              {
                name: 'Edit bow profile',
                item: 'profile/bows/edit/:id',
              },
            ],
          },
          nl: {
            title: 'Profiel - Boog profiel aanpassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Boog profielen',
                item: 'profile/bows',
              },
              {
                name: 'Boog profiel aanpassen',
                item: 'profile/bows/edit/:id',
              },
            ],
          },
        },
      },

      {
        path: 'profile/arrows',
        loadComponent: () => import('./pages/profile/arrows/arrows.component').then(m => m.ProfileArrowsComponent),
        canMatch: [profileMatchGuard],
        resolve: { bows: profileArrowsResolver },
        data: {
          en: {
            title: 'Profile - Arrow profiles',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Arrow profiles',
                item: 'profile/arrows',
              },
            ],
          },
          nl: {
            title: 'Profiel - Pijl profielen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Pijl profielen',
                item: 'profile/arrows',
              },
            ],
          },
        },
      },
      {
        path: 'profile/arrows/new',
        loadComponent: () => import('./pages/profile/arrows/new-arrow.component').then(m => m.ProfileNewArrowComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Profile - Add arrow profile',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Arrow profiles',
                item: 'profile/arrows',
              },
              {
                name: 'Add arrow profile',
                item: 'profile/arrows/new',
              },
            ],
          },
          nl: {
            title: 'Profiel - Nieuw pijl profiel',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Pijl profielen',
                item: 'profile/arrows',
              },
              {
                name: 'Nieuw pijl profiel',
                item: 'profile/arrows/new',
              },
            ],
          },
        },
      },
      {
        path: 'profile/arrows/edit/:id',
        pathMatch: 'full',
        loadComponent: () => import('./pages/profile/arrows/edit-arrow.component').then(m => m.ProfileEditArrowComponent),
        canMatch: [profileMatchGuard],
        resolve: { bows: profileArrowsResolver },
        data: {
          en: {
            title: 'Profile - Edit arrow profile',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Arrow profiles',
                item: 'profile/arrows',
              },
              {
                name: 'Edit arrow profile',
                item: 'profile/arrows/edit/:id',
              },
            ],
          },
          nl: {
            title: 'Profiel - Pijl profiel aanpassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Pijl profielen',
                item: 'profile/arrows',
              },
              {
                name: 'Pijl profiel aanpassen',
                item: 'profile/arrows/edit/:id',
              },
            ],
          },
        },
      },
      {
        path: 'profile/training/session',
        loadComponent: () => import('./pages/profile/training/session/session.component').then(m => m.SessionComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Profile - Training',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Training session',
                item: 'profile/training',
              },
            ],
          },
          nl: {
            title: 'Profiel - Training',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Trainingssessie',
                item: 'profile/training',
              },
            ],
          },
        },
      },
      {
        path: 'profile/training/history',
        loadComponent: () => import('./pages/profile/training/overview/overview.component').then(m => m.OverviewComponent),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Profile - Training history',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Profile',
              },
              {
                name: 'Training history',
                item: 'profile/history',
              },
            ],
          },
          nl: {
            title: 'Profiel - Training geschiedenis',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Profiel',
              },
              {
                name: 'Training geschiedenis',
                item: 'profile/history',
              },
            ],
          },
        },
      },

      {
        path: 'admin/:organization/about',
        pathMatch: 'full',
        loadComponent: () => import('./pages/admin/about/about.component').then(m => m.AdminAboutComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver],
        data: {
          en: {
            title: 'Admin - About organization',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'About organization',
                item: 'admin/:organization/about',
              },
            ],
          },
          nl: {
            title: 'Admin - Over organisatie',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Over organisatie',
                item: 'admin/:organization/about',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/admin/competitions/competitions.component').then(m => m.AdminCompetitionsComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver, adminCompetitionsResolver],
        data: {
          en: {
            title: 'Admin - Organization competitions',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
            ],
          },
          nl: {
            title: 'Admin - Organisatie competities',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions/new',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/admin/competitions/new-competition.component').then(m => m.AdminNewCompetitionComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver],
        data: {
          en: {
            title: 'Admin - Organization new competition',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organization new competition',
                item: 'admin/:organization/competitions/new',
              },
            ],
          },
          nl: {
            title: 'Admin - Organisatie nieuwe competitie',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organisatie nieuwe competitie',
                item: 'admin/:organization/competitions/new',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions/edit/:id',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/admin/competitions/edit-competition.component').then(m => m.AdminEditCompetitionComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver, adminCompetitionsResolver],
        data: {
          en: {
            title: 'Admin - Organization edit competition',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organization edit competition',
                item: 'admin/:organization/competitions/edit/:id',
              },
            ],
          },
          nl: {
            title: 'Admin - Organisatie competitie aanpassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organisatie competitie aanpassen',
                item: 'admin/:organization/competitions/edit/:id',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions/:id/scoring',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/admin/competitions/scoring/scoring.component').then(m => m.AdminCompetitionsScoringComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver, adminCompetitionsResolver],
        data: {
          en: {
            title: 'Admin - Competition scoring',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Scoring',
                item: 'admin/:organization/competitions/:id/scoring',
              },
            ],
          },
          nl: {
            title: 'Admin - Competitie scores',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Scores',
                item: 'admin/:organization/competitions/:id/scoring',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions/:id/live',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/admin/competitions/scoring/scoring.component').then(m => m.AdminCompetitionsScoringComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver, adminCompetitionsResolver],
        data: {
          en: {
            title: 'Admin - Competition live scoring',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Live scoring',
                item: 'admin/:organization/competitions/:id/scoring',
              },
            ],
          },
          nl: {
            title: 'Admin - Competitie live scores',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Live scores',
                item: 'admin/:organization/competitions/:id/scoring',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions/:url/targets',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/admin/targets/targets.component').then(m => m.AdminCompetitionsTargetsComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver, adminCompetitionsResolver],
        data: {
          en: {
            title: 'Admin - Organization competitions targets',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organization competitions targets',
                item: 'admin/:organization/competitions/:url/targets',
              },
            ],
          },
          nl: {
            title: 'Admin - Organisatie competities doelen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organisatie competities doelen',
                item: 'admin/:organization/competitions/:url/targets',
              },
            ],
          },
        },
      },
      {
        path: 'admin/:organization/competitions/:url/targets/:targetNumber',
        pathMatch: 'full',
        loadComponent: () => import('./pages/admin/targets/target.component').then(m => m.AdminCompetitionsTargetComponent),
        canMatch: [adminMatchGuard],
        resolve: [adminOrganizationResolver, adminCompetitionsResolver],
        data: {
          en: {
            title: 'Admin - Organization competitions target',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organization competitions',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organization competitions targets',
                item: 'admin/:organization/competitions/:url/targets',
              },
              {
                name: 'Organization competitions target',
                item: 'admin/:organization/competitions/:url/targets/:targetNumber',
              },
            ],
          },
          nl: {
            title: 'Admin - Organisatie competities doel',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Admin',
              },
              {
                name: 'Organisatie competities',
                item: 'admin/:organization/competitions',
              },
              {
                name: 'Organisatie competities doelen',
                item: 'admin/:organization/competitions/:url/targets',
              },
              {
                name: 'Organisatie competities doel',
                item: 'admin/:organization/competitions/:url/targets/:targetNumber',
              },
            ],
          },
        },
      },

      {
        path: 'superadmin/users',
        loadComponent: () => import('./pages/superadmin/users/users.component').then(m => m.SuperadminUsersComponent),
        canMatch: [superAdminMatchGuard],
        resolve: { users: superadminUsersResolver, organizations: superadminOrganizationsResolver },
        data: {
          en: {
            title: 'Super admin - Users',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Users',
                item: 'superadmin/users',
              },
            ],
          },
          nl: {
            title: 'Super admin - Gebruikers',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Gebruikers',
                item: 'superadmin/users',
              },
            ],
          },
        },
      },
      {
        path: 'superadmin/organizations',
        loadComponent: () =>
          import('./pages/superadmin/organizations/organizations.component').then(m => m.SuperadminOrganizationsComponent),
        canMatch: [superAdminMatchGuard],
        resolve: { organizations: superadminOrganizationsResolver },
        data: {
          en: {
            title: 'Super admin - Organizations',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Organizations',
                item: 'superadmin/organizations',
              },
            ],
          },
          nl: {
            title: 'Super admin - Organisaties',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Organisaties',
                item: 'superadmin/organizations',
              },
            ],
          },
        },
      },
      {
        path: 'superadmin/organizations/new',
        loadComponent: () =>
          import('./pages/superadmin/organizations/new-organization.component').then(
            m => m.SuperadminNewOrganizationComponent
          ),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Super admin - New organization',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Organizations',
                item: 'superadmin/organizations',
              },
              {
                name: 'New organization',
                item: 'superadmin/organizations/new',
              },
            ],
          },
          nl: {
            title: 'Super admin - Nieuwe organisatie',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Organisaties',
                item: 'superadmin/organizations',
              },
              {
                name: 'Nieuwe organisatie',
                item: 'superadmin/organizations/new',
              },
            ],
          },
        },
      },
      {
        path: 'superadmin/organizations/edit/:id',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/superadmin/organizations/edit-organization.component').then(
            m => m.SuperadminEditOrganizationComponent
          ),
        canMatch: [profileMatchGuard],
        data: {
          en: {
            title: 'Super admin - Edit organization',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Organizations',
                item: 'superadmin/organizations',
              },
              {
                name: 'Edit organization',
                item: 'superadmin/organizations/edit/:id',
              },
            ],
          },
          nl: {
            title: 'Super admin - Organisatie aanpassen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Super admin',
              },
              {
                name: 'Organisaties',
                item: 'superadmin/organizations',
              },
              {
                name: 'Organisatie aanpassen',
                item: 'superadmin/organizations/edit/:id',
              },
            ],
          },
        },
      },

      {
        path: 'organization/:organization',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/public-organization/about/about.component').then(m => m.OrganizationAboutComponent),
        resolve: [publicOrganizationResolver],
        data: {
          revalidate: 0,
          en: {
            title: 'About organization',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Organization',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
            ],
          },
          nl: {
            title: 'Over organisatie',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Organisatie',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
            ],
          },
        },
      },
      {
        path: 'organization/:organization/competitions',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/public-organization/competitions/competitions.component').then(
            m => m.OrganizationCompetitionsComponent
          ),
        resolve: [publicOrganizationResolver],
        data: {
          revalidate: 0,
          en: {
            title: 'Organization competitions',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Organization',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
              {
                name: 'Competitions',
                item: 'organization/:organization/competitions',
              },
            ],
          },
          nl: {
            title: 'Organisatie competities',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Organisatie',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
              {
                name: 'Competities',
                item: 'organization/:organization/competitions',
              },
            ],
          },
        },
      },
      {
        path: 'organization/:organization/competitions/:url',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/public-organization/competitions/competition.component').then(
            m => m.OrganizationCompetitionComponent
          ),
        resolve: [publicOrganizationResolver],
        data: {
          revalidate: 0,
          en: {
            title: 'Organization competition',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Organization',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
              {
                name: 'Competitions',
                item: 'organization/:organization/competitions',
              },
              {
                name: 'Competition',
                item: 'organization/:organization/competitions/:url',
              },
            ],
          },
          nl: {
            title: 'Organisatie competitie',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Organisatie',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
              {
                name: 'Competities',
                item: 'organization/:organization/competitions',
              },
              {
                name: 'Competitie',
                item: 'organization/:organization/competitions/:url',
              },
            ],
          },
        },
      },
      {
        path: 'organization/:organization/competitions/:url/participate',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/public-organization/competitions/participate/participate.component').then(
            m => m.OrganizationCompetitionParticipateComponent
          ),
        resolve: [publicOrganizationResolver],
        data: {
          en: {
            title: 'Organization competition participate',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Organization',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
              {
                name: 'Competitions',
                item: 'organization/:organization/competitions',
              },
              {
                name: 'Competition',
                item: 'organization/:organization/competitions/:url',
              },
              {
                name: 'Participate',
                item: 'organization/:organization/competitions/:url/participate',
              },
            ],
          },
          nl: {
            title: 'Organisatie competitie deelnemen',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Organisatie',
              },
              {
                name: ':organization',
                item: 'organization/:organization',
              },
              {
                name: 'Competities',
                item: 'organization/:organization/competitions',
              },
              {
                name: 'Competitie',
                item: 'organization/:organization/competitions/:url',
              },
              {
                name: 'Deelnemen',
                item: 'organization/:organization/competitions/:url/participate',
              },
            ],
          },
        },
      },

      {
        path: 'legal/cookie-policy',
        loadComponent: () => import('./pages/legal/cookie-policy.component').then(m => m.CookiePolicyComponent),
        data: {
          en: {
            title: 'Cookie Policy',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Legal',
                item: 'legal',
              },
              {
                name: 'Cookie Policy',
                item: 'legal/cookie-policy',
              },
            ],
          },
          nl: {
            title: 'Cookiebeleid',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Juridisch',
                item: 'legal',
              },
              {
                name: 'Cookiebeleid',
                item: 'legal/cookie-policy',
              },
            ],
          },
        },
      },
      {
        path: 'legal/privacy-policy',
        loadComponent: () => import('./pages/legal/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
        data: {
          en: {
            title: 'Privacy Policy',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Legal',
                item: 'legal',
              },
              {
                name: 'Privacy Policy',
                item: 'legal/privacy-policy',
              },
            ],
          },
          nl: {
            title: 'Privacybeleid',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Juridisch',
                item: 'legal',
              },
              {
                name: 'Privacybeleid',
                item: 'legal/privacy-policy',
              },
            ],
          },
        },
      },
      {
        path: 'legal/terms-of-service',
        loadComponent: () => import('./pages/legal/terms-of-service.component').then(m => m.TermsOfServiceComponent),
        data: {
          en: {
            title: 'Terms of Service',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Legal',
                item: 'legal',
              },
              {
                name: 'Terms of Service',
                item: 'legal/terms-of-service',
              },
            ],
          },
          nl: {
            title: 'Algemene voorwaarden',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Juridisch',
                item: 'legal',
              },
              {
                name: 'Algemene voorwaarden',
                item: 'legal/terms-of-service',
              },
            ],
          },
        },
      },
      {
        path: 'legal/eula',
        loadComponent: () => import('./pages/legal/eula.component').then(m => m.EulaComponent),
        data: {
          en: {
            title: 'EULA',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Legal',
                item: 'legal',
              },
              {
                name: 'EULA',
                item: 'legal/eula',
              },
            ],
          },
          nl: {
            title: 'EULA',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Juridisch',
                item: 'legal',
              },
              {
                name: 'EULA',
                item: 'legal/eula',
              },
            ],
          },
        },
      },
      {
        path: 'legal/image-license',
        loadComponent: () => import('./pages/legal/image-license.component').then(m => m.ImageLicenseComponent),
        data: {
          en: {
            title: 'Image license',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Legal',
                item: 'legal',
              },
              {
                name: 'Image license',
                item: 'legal/image-license',
              },
            ],
          },
          nl: {
            title: 'Image license',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Juridisch',
                item: 'legal',
              },
              {
                name: 'Afbeelding licentie',
                item: 'legal/image-license',
              },
            ],
          },
        },
      },
      {
        path: 'legal',
        pathMatch: 'full',
        loadComponent: () => import('./pages/legal/legal.component').then(m => m.LegalComponent),
        data: {
          en: {
            title: 'legal',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Legal',
                item: 'legal',
              },
            ],
          },
          nl: {
            title: 'EULA',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Juridisch',
                item: 'legal',
              },
            ],
          },
        },
      },

      {
        path: 'sitemap',
        pathMatch: 'full',
        loadComponent: () => import('./pages/sitemap/sitemap.component').then(m => m.SitemapComponent),
        data: {
          en: {
            title: 'sitemap',
            breadcrumbs: [
              {
                name: 'Home',
                item: '/',
              },
              {
                name: 'Sitemap',
                item: 'sitemap',
              },
            ],
          },
          nl: {
            title: 'sitemap',
            breadcrumbs: [
              {
                name: 'Startpagina',
                item: '/',
              },
              {
                name: 'Sitemap',
                item: 'sitemap',
              },
            ],
          },
        },
      },
    ],
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/404',
  },
];
