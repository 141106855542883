import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { mergeWith, Observable, of } from 'rxjs';
import type { ICompetition } from '@archery-scoring/models/competition.model';
import { inject } from '@angular/core';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminCompetitionsStore } from '../../store/admin/admin-competitions.store';
import { LocalizeRouterService } from '@owain/transloco-utils/localize-router.service';
import { CallState } from '@owain/store-features/features/call-state/call-state.feature';

export const adminCompetitionsResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<ICompetition[] | IMiscHttpError | null>
  | Promise<ICompetition[] | IMiscHttpError | null>
  | ICompetition[]
  | IMiscHttpError
  | null => {
  const adminCompetitionsStore: InstanceType<typeof AdminCompetitionsStore> = inject(AdminCompetitionsStore);

  if (adminCompetitionsStore.competitionsLoaded()) {
    return adminCompetitionsStore.competitionEntities();
  }

  const router: Router = inject(Router);
  const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);

  const error$: Observable<null> = toObservable(adminCompetitionsStore.competitionsError).pipe(
    filter((err: string | null): err is string => err != null),
    tap((err: string): void => console.error(err)),
    tap((): Promise<boolean> => router.navigateByUrl(<string>localizeRouterService.translateRoute('/500'))),
    map((): null => null)
  );

  const loaded$: Observable<ICompetition[]> = toObservable(adminCompetitionsStore.competitionsCallState).pipe(
    filter((callState: CallState): callState is 'loaded' => callState === 'loaded'),
    switchMap((): Observable<ICompetition[]> => of(adminCompetitionsStore.competitionEntities()))
  );
  adminCompetitionsStore.getCompetitions();

  return loaded$.pipe(mergeWith(error$));
};
