import { CanMatchFn, GuardResult, MaybeAsync, Route, Router, UrlSegment } from '@angular/router';
import { inject } from '@angular/core';
import { CookieService } from '../services/cookie.service';
import { TranslocoService } from '@jsverse/transloco';
import { LocalizeRouterService } from '@owain/transloco-utils/localize-router.service';

export const languageMatchGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> => {
  const router: Router = inject(Router);
  const cookieService: CookieService = inject(CookieService);
  const translocoService: TranslocoService = inject(TranslocoService);
  const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);

  const language = cookieService.get('translocoLang');

  if (language && language !== '') {
    translocoService.setDefaultLang(language);
    translocoService.setActiveLang(language);

    return router.parseUrl(<string>localizeRouterService.translateRoute('/'));
  } else {
    const browserLang = localizeRouterService.getBrowserLang();
    const defaultLang = browserLang || translocoService.getDefaultLang() || localizeRouterService.firstLocale() || 'en';

    translocoService.setDefaultLang(defaultLang);
    translocoService.setActiveLang(defaultLang);

    return router.parseUrl(<string>localizeRouterService.translateRoute('/'));
  }
};
