import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import type { IUser } from '@archery-scoring/models/user.model';
import { mergeWith, Observable, of } from 'rxjs';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SuperAdminUsersStore } from '../../store/superadmin/superadmin-user.store';
import { CallState } from '@owain/store-features/features/call-state/call-state.feature';

export const superadminUsersResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<IUser[] | IMiscHttpError | null>
  | Promise<IUser[] | IMiscHttpError | null>
  | IUser[]
  | IMiscHttpError
  | null => {
  const superAdminUsersStore: InstanceType<typeof SuperAdminUsersStore> = inject(SuperAdminUsersStore);

  if (superAdminUsersStore.usersLoaded()) {
    return superAdminUsersStore.userEntities();
  }

  const router: Router = inject(Router);

  const error$: Observable<null> = toObservable(superAdminUsersStore.usersError).pipe(
    filter((err: string | null): err is string => err != null),
    tap((err: string): void => console.error(err)),
    tap((): Promise<boolean> => router.navigateByUrl('/500')),
    map((): null => null)
  );

  const loaded$: Observable<IUser[]> = toObservable(superAdminUsersStore.usersCallState).pipe(
    filter((callState: CallState): callState is 'loaded' => callState === 'loaded'),
    switchMap((): Observable<IUser[]> => of(superAdminUsersStore.userEntities()))
  );
  superAdminUsersStore.getUsers();

  return loaded$.pipe(mergeWith(error$));
};
