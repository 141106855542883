<div class="container-wrapper">
  <div>
    @if (!hideLayout()) {
      <div class="relative z-40 lg:hidden h-full" role="dialog" aria-modal="true" aria-label="Mobile menu">
        @if (menuOpen()) {
          <div class="fixed inset-0 bg-black bg-opacity-25" @menuBackdrop></div>

          <div class="fixed inset-0 z-40 flex h-full" @menu>
            <div
              class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white dark:bg-slate-800 pb-12 shadow-xl h-full mobile-inset-top"
              (clickOutside)="outsideMenuClick()">
              <div class="flex px-4 pb-2 pt-5">
                <div class="mobile-inset-left">
                  <button
                    (click)="toggleMenu(true)"
                    type="button"
                    class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-600 dark:text-gray-200">
                    <span class="absolute -inset-0.5"></span>
                    <span class="sr-only">Close menu</span>
                    @defer (hydrate never) {
                      <fast-svg name="icons/menu-close" size="24" />
                    }
                  </button>
                </div>
              </div>

              <div class="space-y-6 px-4 py-6">
                <div class="flow-root mobile-inset-left">
                  <a
                    (click)="toggleMenu(true)"
                    [routerLink]="['/'] | localize"
                    class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                    {{ 'home' | transloco }}</a>
                </div>
                <div class="flow-root mobile-inset-left">
                  <a
                    (click)="toggleMenu(true)"
                    [routerLink]="['/about'] | localize"
                    class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                    {{ 'about' | transloco }}
                  </a>
                </div>
              </div>

              <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                <div class="flow-root mobile-inset-left">
                  <a
                    (click)="toggleMenu(true)"
                    [routerLink]="['/scoring'] | localize"
                    class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                    {{ 'scoring' | transloco }}
                  </a>
                </div>
                <div class="flow-root mobile-inset-left">
                  <a
                    (click)="toggleMenu(true)"
                    [routerLink]="['/classes', 'international-field-archery-association'] | localize"
                    class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                    {{ 'bows' | transloco }}
                  </a>
                </div>
              </div>

              @if (authenticated()) {
                @if (isSuperAdmin()) {
                  <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                    <div class="flow-root mobile-inset-left">
                      <a
                        (click)="toggleMenu(true)"
                        [routerLink]="['/superadmin', 'users'] | localize"
                        (prefetch)="prefetchSuperAdmin()"
                        [prefetchMode]="['hover', 'visible']"
                        class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                        Super admin
                      </a>
                    </div>
                  </div>
                }

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/profile', 'settings'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'accountsettings' | transloco }}
                    </a>
                  </div>
                </div>

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/profile', 'training', 'session'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'trainingsession' | transloco }}
                    </a>
                  </div>
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/profile', 'training', 'history'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'traininghistory' | transloco }}
                    </a>
                  </div>
                </div>

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/profile', 'bows'] | localize"
                      (prefetch)="prefetchBows()"
                      [prefetchMode]="['hover', 'visible']"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'bowprofiles' | transloco }}
                    </a>
                  </div>
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/profile', 'arrows'] | localize"
                      (prefetch)="prefetchArrows()"
                      [prefetchMode]="['hover', 'visible']"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'arrowprofiles' | transloco }}
                    </a>
                  </div>
                </div>

                @if (hasAnyOrganization()) {
                  <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                    @for (organization of organizations(); track organization.id) {
                      <div class="flow-root mobile-inset-left">
                        <a
                          (click)="toggleMenu(true)"
                          [routerLink]="['/admin', organization.url, 'about'] | localize"
                          [prefetchMode]="['hover']"
                          (prefetch)="prefetchAdmin()"
                          [prefetchMode]="['hover', 'visible']"
                          class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                          {{ organization.name }}
                        </a>
                      </div>
                    }
                  </div>
                }

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/signout'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'signout' | transloco }}
                    </a>
                  </div>
                </div>
              } @else {
                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="signIn(true)"
                      class="cursor-pointer -m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'signin' | transloco }}
                    </a>
                  </div>
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/register'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'register' | transloco }}
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }

    <div>
      @if (!hideLayout()) {
        <header class="relative z-1 bg-white/75 dark:bg-slate-800/75 mobile-inset-top">
          <nav aria-label="Top" class="mx-auto px-4 sm:px-6 lg:px-8 border-b border-gray-300 dark:border-gray-500">
            <div class="mobile-inset-left mobile-inset-right">
              <div class="flex h-16 items-center">
                <button
                  (click)="toggleMenu()"
                  type="button"
                  class="relative rounded-md p-2 text-gray-600 dark:text-gray-200 lg:hidden">
                  <span class="absolute -inset-0.5"></span>
                  <span class="sr-only">Open menu</span>
                  @defer (hydrate never) {
                    <fast-svg name="icons/menu-hamburger" size="24" />
                  }
                </button>

                <div class="ml-2 flex lg:ml-0">
                  <a [routerLink]="['/'] | localize">
                    <span class="sr-only">Archery Scoring</span>
                    @defer (hydrate never) {
                      <fast-svg name="logo/crossed-arrows" size="32" />
                    }
                  </a>
                </div>

                <div class="hidden lg:ml-8 lg:block lg:self-stretch">
                  <div class="flex h-full space-x-8">
                    <a
                      [routerLink]="['/'] | localize"
                      class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                      {{ 'home' | transloco }}
                    </a>
                    <a
                      [routerLink]="['/about'] | localize"
                      class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                      {{ 'about' | transloco }}
                    </a>
                    <a
                      [routerLink]="['/scoring'] | localize"
                      class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                      {{ 'scoring' | transloco }}
                    </a>
                    <a
                      [routerLink]="['/classes', 'international-field-archery-association'] | localize"
                      class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                      {{ 'bows' | transloco }}
                    </a>
                  </div>
                </div>

                <div class="ml-auto flex items-center">
                  @defer (on viewport; prefetch on idle; hydrate on idle) {
                    <app-dropdown
                      text="Account"
                      class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                      @if (authenticated()) {
                        @if (isSuperAdmin()) {
                          <div class="py-1" role="none">
                            <a
                              [routerLink]="['/superadmin', 'users'] | localize"
                              (prefetch)="prefetchSuperAdmin()"
                              [prefetchMode]="['hover', 'visible']"
                              class="group flex items-center px-4 py-2 text-sm"
                              role="menuitem">
                              <div class="mr-3">
                                @defer (hydrate never) {
                                  <fast-svg name="icons/star" size="20" />
                                }
                              </div>
                              Super admin
                            </a>
                          </div>
                        }

                        <div class="py-1" role="none">
                          <a
                            [routerLink]="['/profile', 'settings'] | localize"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/settings" size="20" />
                              }
                            </div>
                            {{ 'accountsettings' | transloco }}
                          </a>
                        </div>

                        <div class="py-1" role="none">
                          <a
                            [routerLink]="['/profile', 'training', 'session'] | localize"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/trophy" size="20" />
                              }
                            </div>
                            {{ 'trainingsession' | transloco }}
                          </a>
                          <a
                            [routerLink]="['/profile', 'training', 'history'] | localize"
                            (prefetch)="prefetchTrainingHistory()"
                            [prefetchMode]="['hover', 'visible']"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/archive" size="20" />
                              }
                            </div>
                            {{ 'traininghistory' | transloco }}
                          </a>
                        </div>

                        <div class="py-1" role="none">
                          <a
                            [routerLink]="['/profile', 'bows'] | localize"
                            (prefetch)="prefetchBows()"
                            [prefetchMode]="['hover', 'visible']"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/bow" size="20" />
                              }
                            </div>
                            {{ 'bowprofiles' | transloco }}
                          </a>
                          <a
                            [routerLink]="['/profile', 'arrows'] | localize"
                            (prefetch)="prefetchArrows()"
                            [prefetchMode]="['hover', 'visible']"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/arrow" size="20" />
                              }
                            </div>
                            {{ 'arrowprofiles' | transloco }}
                          </a>
                        </div>

                        @if (hasAnyOrganization()) {
                          <div class="py-1" role="none">
                            @for (organization of organizations(); track organization.id) {
                              <a
                                [routerLink]="['/admin', organization.url, 'about'] | localize"
                                [prefetchMode]="['hover']"
                                (prefetch)="prefetchAdmin()"
                                [prefetchMode]="['hover', 'visible']"
                                class="group flex items-center px-4 py-2 text-sm"
                                role="menuitem">
                                <div class="mr-3">
                                  @defer (hydrate never) {
                                    <fast-svg name="icons/organizations" size="20" />
                                  }
                                </div>
                                {{ organization.name }}
                              </a>
                            }
                          </div>
                        }

                        <div class="py-1" role="none">
                          <a
                            [routerLink]="['/signout'] | localize"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/sign-out" size="20" />
                              }
                            </div>
                            {{ 'signout' | transloco }}
                          </a>
                        </div>
                      } @else {
                        <div class="py-1" role="none">
                          <a
                            (click)="signIn(false)"
                            class="cursor-pointer group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/sign-in" size="20" />
                              }
                            </div>
                            {{ 'signin' | transloco }}
                          </a>
                          <a
                            [routerLink]="['/register'] | localize"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              @defer (hydrate never) {
                                <fast-svg name="icons/register" size="20" />
                              }
                            </div>
                            {{ 'register' | transloco }}
                          </a>
                        </div>
                      }
                    </app-dropdown>
                  } @placeholder {
                    <div>
                      <button
                        type="button"
                        aria-roledescription="Dropdown menu"
                        class="appearance-none inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 text-sm font-medium focus:outline-none text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 bg-gray-200 dark:bg-slate-900 border-gray-100 hover:bg-gray-300 dark:border-gray-500 dark:hover:bg-gray-800"
                        id="menu-button">
                        <span class="duration-0">Account</span>
                        <div class="h-5 w-5 -mr-1 -mt-0.5 ml-2 duration-0">
                          <fast-svg name="icons/chevron-down" size="20" />
                        </div>
                      </button>
                    </div>
                  }

                  <div class="ml-8 flex cursor-pointer">
                    @defer (on viewport; prefetch on idle; hydrate on idle) {
                      <app-dropdown [button]="false" [inlineBlock]="false">
                        <div
                          slot="start"
                          class="flex items-center text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 cursor-pointer">
                          @if (language() == 'en') {
                            <fast-svg name="flags/us" size="20" class="block h-auto w-5 flex-shrink-0" />
                          } @else {
                            <fast-svg name="flags/nl" size="20" class="block h-auto w-5 flex-shrink-0" />
                          }
                          <span
                            class="ml-3 block text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 uppercase">
                            {{ language() | language:'SHORT' }}
                          </span>
                          <span class="sr-only">, change language</span>
                        </div>
                        @for (lang of ['en', 'nl']; track $index) {
                          <div class="py-1" role="none">
                            <button
                              class="group flex items-center px-4 py-2 text-sm w-full"
                              role="menuitem"
                              (click)="switchLang(lang)">
                              @defer (hydrate never) {
                                <fast-svg
                                  [name]="'flags/' + (lang | language:'FLAG')"
                                  size="20"
                                  class="block h-auto w-5 flex-shrink-0" />
                              }
                              <span class="ml-3 block text-sm font-medium">{{ lang | language:'LONG' }}</span>
                              <span class="sr-only">, {{ lang | language:'LONG' }}</span>
                            </button>
                          </div>
                        }
                      </app-dropdown>
                    } @placeholder {
                      <div class="inline-block ml-4 relative text-left">
                        <div>
                          @if (language() == 'en') {
                            <fast-svg name="flags/us" size="20" class="block h-auto w-5 flex-shrink-0" />
                          } @else {
                            <fast-svg name="flags/nl" size="20" class="block h-auto w-5 flex-shrink-0" />
                          }
                        </div>
                      </div>
                    }
                  </div>

                  <div class="ml-8 flex">
                    <button (click)="switchTheme()" class="flex items-center filter-white dark:filter-black">
                      @if (theme()) {
                        <fast-svg
                          name="theme/sun"
                          color="#E5E7EB"
                          size="20"
                          class="block h-auto w-5 flex-shrink-0 cursor-pointer text-gray-100" />
                      } @else {
                        <fast-svg
                          name="theme/moon"
                          color="#374151"
                          size="20"
                          class="block h-auto w-5 flex-shrink-0 cursor-pointer text-gray-900" />
                      }
                      <span class="sr-only">, change theme</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      }

      <router-outlet />
    </div>
  </div>

  @if (!hideLayout()) {
    <app-footer />
  }
</div>

<notifier-container class="w-full" />
<app-scroll-top-top />

<ng-template #notification let-notificationData="notification">
  <div
    class="max-w-sm w-full bg-gray-900 shadow-lg rounded-lg cursor-pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0 pt-0.5">
          @if (notificationData.type === 'success') {
            @defer (hydrate never) {
              <fast-svg name="icons/checkcircle" size="20" class="block h-5 w-5 text-green-400 mt-3p" />
            }
          } @else if (notificationData.type === 'info') {
            @defer (hydrate never) {
              <fast-svg name="icons/infocircle" size="20" class="block h-5 w-5 text-blue-400 mt-3p" />
            }
          } @else if (notificationData.type === 'error') {
            @defer (hydrate never) {
              <fast-svg name="icons/xcircle" size="20" class="block h-5 w-5 text-red-400 mt-3p" />
            }
          } @else if (notificationData.type === 'warning') {
            @defer (hydrate never) {
              <fast-svg name="icons/exclamationcircle" size="20" class="block h-5 w-5 text-orange-400 mt-3p" />
            }
          }
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-white mt-3p">
            {{ notificationData.message }}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            (click)="onClickDismiss(notificationData.id)"
            class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            @defer (hydrate never) {
              <fast-svg class="block h-5 w-5 text-white mt-3p" name="icons/x" size="20" />
            }
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
