import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/http/auth.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@owain/transloco-utils/localize-router.service';
import { IUser } from '@astranocta/models/user.model';

export const jwtRefreshTokenInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const router: Router = inject(Router);
  const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
  const authService: AuthService = inject(AuthService);

  let isRefreshing: boolean = false;

  req = req.clone({
    withCredentials: true,
  });

  return next(req).pipe(
    catchError((error: any): Observable<HttpEvent<unknown>> => {
      if (error instanceof HttpErrorResponse && !req.url.includes('sign-in') && error.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;

          return authService.refreshToken().pipe(
            switchMap((user: IUser): Observable<HttpEvent<unknown>> => {
              isRefreshing = false;

              return next(req);
            }),
            catchError((error: any): Observable<never> => {
              isRefreshing = false;

              if (error.status == '403') {
                router.navigateByUrl(<string>localizeRouterService.translateRoute('/signout'));
              }

              return throwError(() => error);
            })
          );
        }

        return next(req);
      }

      return throwError(() => error);
    })
  );
};
