import {
  CanMatchFn,
  GuardResult,
  MaybeAsync,
  RedirectCommand,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { inject, PLATFORM_ID } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';
import { AuthService } from '../../services/http/auth.service';
import type { IUser } from '@archery-scoring/models/user.model';
import { UserStore } from '../../store/user/user.store';
import { isPlatformBrowser } from '@angular/common';
import { ApplicationUiStore } from '../../store/application-ui/application-ui.store';
import { Observable, of } from 'rxjs';

export const profileMatchGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> => {
  const userStore: InstanceType<typeof UserStore> = inject(UserStore);

  // if (userStore.authenticated()) {
  //   return true;
  // }

  const applicationUiStore: InstanceType<typeof ApplicationUiStore> = inject(ApplicationUiStore);
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  const platformId: Object = inject(PLATFORM_ID);

  return authService.status(isPlatformBrowser(platformId)).pipe(
    take(1),
    map((user: IUser | null): user is IUser => {
      return user != null;
    }),
    map(status => {
      if (!status) {
        applicationUiStore.setRedirectUrl(route.path);

        const targetOfCurrentNavigation: UrlTree | undefined = router.getCurrentNavigation()?.finalUrl;
        const redirect: UrlTree = router.parseUrl('/401');

        return new RedirectCommand(redirect, {
          browserUrl: targetOfCurrentNavigation,
        });
      }

      return true;
    }),
    catchError((err: any): Observable<boolean> => {
      console.error('[profileMatchGuard] error:', err);
      return of(false);
    })
  );
};
